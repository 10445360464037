import React from "react";
import Count, { CountdownRenderProps } from "react-countdown";

function pluraliseEnglish(count: number) {
  return count === 1 ? "" : "s";
}

function renderer(args: CountdownRenderProps & { lang: "af" | "en" }) {
  if (args.completed)
    return <strong>{args.lang === "en" ? "right now" : "nou"}</strong>;

  if (args.lang === "en") {
    return (
      <React.Fragment>
        in{" "}
        <strong>
          {args.days} day{pluraliseEnglish(args.days)}
        </strong>
        ,{" "}
        <strong>
          {args.hours} hour{pluraliseEnglish(args.hours)}
        </strong>
        ,{" "}
        <strong>
          {args.minutes} minute{pluraliseEnglish(args.minutes)}
        </strong>{" "}
        and{" "}
        <strong>
          {args.seconds} second{pluraliseEnglish(args.seconds)}
        </strong>
        .
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      in{" "}
      <strong>
        {args.days} {args.days === 1 ? "dag" : "dae"}
      </strong>
      ,{" "}
      <strong>
        {args.hours} {args.hours === 1 ? "uur" : "ure"}
      </strong>
      ,{" "}
      <strong>
        {args.minutes} {args.minutes === 1 ? "minuut" : "minute"}
      </strong>{" "}
      en{" "}
      <strong>
        {args.seconds} {args.seconds === 1 ? "sekonde" : "sekondes"}
      </strong>
      .
    </React.Fragment>
  );
}

export function Countdown({ lang }: { lang: "af" | "en" }): JSX.Element {
  const date = new Date(2024, 11, 13, 15, 0);

  return (
    <Count
      precision={3}
      date={date}
      renderer={(props) => renderer({ ...props, lang })}
    />
  );
}

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./landing.module.css";
import classNames from "classnames";
import { Hr } from "../../components/hr/hr";

interface ILandingPageProps {
  name?: string;
}

export function LandingPage(props: ILandingPageProps): JSX.Element {
  const [activeHover, setActiveHover] = useState<string>();

  const noHover = !activeHover;
  const showAfrikaans = activeHover === "af" || noHover;
  const showEnglish = activeHover === "en" || noHover;

  return (
    <div className={styles.landingPageWrapper}>
      <div className={styles.landingPageInner}>
        <div className={styles.titleWrapper}>
          <img
            src="ornament1.svg"
            className={"displayOrnament displayOrnamentTop"}
            alt=""
          />

          <h1 className={classNames("display1", styles.displayHeading)}>
            <img src="/endpoint1.svg" alt="" />
            Josh &amp; Mira
            <img src="/endpoint1.svg" alt="" />
          </h1>

          <p>
            <a
              href="https://maps.app.goo.gl/1pRjnfgFuhyLP6fm9"
              target="_blank"
              rel="noreferrer noopener"
              className="clearLink"
            >
              <strong>Rivier Plaas, Meyerton</strong>, Langenhoven Rd, Sherman
              Park AH, Meyerton, 1961
            </a>
          </p>

          <Hr paddingBottom={10} maxWidth={70} paddingTop={10} />

          <p
            className={classNames(
              styles.paddedParagraph,
              styles.largerParagraph
            )}
          >
            {showAfrikaans ? "Vrydag" : ""}
            {noHover ? "/" : ""}
            {showEnglish ? "Friday" : ""}, 13/12/2024, <strong>15h00</strong>
          </p>

          <p className={styles.paddedParagraph}>
            RSVP {showAfrikaans ? "voor" : ""}
            {noHover ? "/" : ""}
            {showEnglish ? "by" : ""} <strong>31/10/2024</strong>
          </p>

          <img
            src="ornament1.svg"
            className={"displayOrnament displayOrnamentBottom"}
            alt=""
          />
        </div>

        <div className={styles.welcomeWrapper}>
          <h2>
            {showAfrikaans ? "Welkom" : ""}
            {noHover ? "/" : ""}
            {showEnglish ? "Welcome" : ""}
          </h2>
          <h4>
            {showAfrikaans ? "Watter taal verkies u?" : ""}
            {noHover ? "/" : ""}
            {showEnglish ? "What language do you prefer?" : ""}
          </h4>
        </div>

        <div className={styles.buttonWrapper}>
          <NavLink
            className={"button"}
            to={"/af"}
            onMouseOver={() => setActiveHover("af")}
            onMouseLeave={() => setActiveHover(void 0)}
          >
            Afrikaans
          </NavLink>

          <NavLink
            to={"/en"}
            className={"button"}
            onMouseOver={() => setActiveHover("en")}
            onMouseLeave={() => setActiveHover(void 0)}
          >
            English
          </NavLink>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import styles from "./hr.module.css";

interface IHrProps {
  maxWidth?: number;
  paddingBottom?: number;
  paddingTop?: number;
}

export function Hr(props: IHrProps): JSX.Element {
  const { maxWidth = 350, paddingBottom = 97, paddingTop = 0 } = props;

  return (
    <div className={styles.underline} style={{ paddingBottom, paddingTop }}>
      <img draggable={false} src="/hr.svg" alt="" style={{ maxWidth }} />
    </div>
  );
}

import React, { PropsWithChildren } from "react";
import styles from "./container.module.css";
import classNames from "classnames";

interface IContainerProps {
  minHeight?: number | string;
  centerContent?: boolean;
  topTitle?: string;
  bottomTitle?: string;
  monogram?: boolean;
}

export function Container(
  props: PropsWithChildren<IContainerProps>
): JSX.Element {
  return (
    <div
      className={classNames(styles.containerWrapper, {
        [styles.centerContent]: props.centerContent,
      })}
      style={{ minHeight: props.minHeight }}
    >
      <div className={styles.containerContent}>{props.children}</div>

      <figure>
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
      </figure>

      {props.monogram && (
        <div className={styles.monogramWrapper}>
          <img src="/monogram.svg" alt="" />
        </div>
      )}

      {props.topTitle && (
        <span className={classNames(styles.title, styles.topTitle)}>
          {props.topTitle}
        </span>
      )}

      {props.topTitle && (
        <span className={classNames(styles.title, styles.bottomTitle)}>
          {props.bottomTitle ?? props.topTitle}
        </span>
      )}
    </div>
  );
}

import React from "react";
import { Countdown } from "../components/countdown/countdown";

interface LanguageCopyAbstract {
  title: string;
  timeFancy: string;
  invite: string;
  inviteJoiner: string;
  inviteEnd: string;
  venue: SectionDetail;
  dressCode: SectionDetail;
  messageBoard: SectionDetail;
  schedule: ScheduleDetail;
  rsvp: SectionDetail;
  greeting: string;
  lodging: SectionDetail;
}

export interface SectionDetail {
  title: string;
  description: () => JSX.Element;
}

export interface ScheduleItem {
  title: string;
  time?: string;
  tagline?: string;
  hasNote?: boolean;
  link?: string;
}

export interface ScheduleDetail extends SectionDetail {
  items: ScheduleItem[];
  note?: string;
}

export const LanguageEngine: { [lang: string]: LanguageCopyAbstract } = {
  en: {
    title: "Josh & Mira",
    timeFancy: `Three O'Clock`,
    invite:
      "Andries and Adéle Vorster warmly invite you to the wedding of their daughter",
    inviteJoiner: "to",
    inviteEnd: `Son of Neil and Felicity Britz`,
    venue: {
      title: "The Venue",
      description: () => {
        return (
          <React.Fragment>
            <a
              draggable={false}
              href="https://maps.app.goo.gl/1pRjnfgFuhyLP6fm9"
              target="_blank"
              rel="noreferrer noopener"
              className="venueBlock"
            >
              <h5>Our wedding will take place at</h5>
              <h1>Rivier Plaas, Meyerton</h1>
              <span>Langenhoven Rd, Sherman Park AH, Meyerton, 1961</span>
              <h5>
                Beginning at <strong>15h00</strong>
              </h5>
            </a>
          </React.Fragment>
        );
      },
    },
    dressCode: {
      title: "What to wear",
      description: () => {
        return (
          <p>
            In the spirit of joy and celebration please wear colourful,
            semi-formal clothes... don't forget your dancing shoes.
          </p>
        );
      },
    },
    messageBoard: {
      title: "Countdown",
      description: () => {
        return (
          <div className="messageBlock">
            <h4>
              We're counting the seconds, how the time flies... We are getting
              married <Countdown lang="en" />
            </h4>
          </div>
        );
      },
    },
    schedule: {
      title: "Ceremony Program",
      description: () => (
        <>
          <p style={{ paddingBottom: 24 }}>
            We are excited to be celebrating our special day with you. Please
            see the schedule for the day. The ceremony begins at{" "}
            <strong>15h00</strong>.
          </p>

          <p>
            We ask that guest please begin arriving from <strong>14h30</strong>{" "}
            so that the ceremony can begin at <strong>15h00</strong>.
          </p>
        </>
      ),
      items: [
        {
          title: "Begin arriving",
          time: "14h30",
          tagline: "Rivier Plaas",
        },
        {
          title: "Marriage ceremony",
          time: "15h00",
          tagline: "Rivier Plaas chapel",
        },
        {
          title: "Group photo",
          time: "16h20",
          tagline: "Outside the chapel",
        },
        {
          title: "Tea and coffee",
          time: "16h30",
          tagline: "Served while the couple takes photos",
        },
        {
          title: "Reception",
          time: "18h00",
          tagline: "In the hall outside the chapel",
        },
      ],
      note: `Coffee, tea and soft drinks will be provided as well as wine for the tables. Other beverages can be purchased from the cash bar at the venue. Unfortunately guests may not bring their own alcohol.`,
    },
    rsvp: {
      title: "RSVP",
      description: () => {
        return (
          <div className="rsvpBlock">
            <p>
              We ask that guests please RSVP before{" "}
              <strong>1 November 2024 </strong> so that we can confirm the final
              numbers for catering.
            </p>

            <p>
              Please RSVP to <strong>Josh</strong> or <strong>Mira</strong>{" "}
              privately.
              <br />
              <strong>Josh</strong>: 0662669858
              <br />
              <strong>Mira</strong>: 0725325279
            </p>
          </div>
        );
      },
    },
    lodging: {
      title: "Lodging",
      description: () => {
        return (
          <>
            <p>
              We know that it will be a far drive for some people, and we would
              hate for that to mean that you can't take part in the festivities.
              Here are some places nearby where you can find lodging if you want
              to stay the night.
            </p>

            <ul className="lodgingList">
              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/plaaskombuis-country-estate"
                  >
                    Plaaskombuis
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/klip-river-country-estate"
                  >
                    Klip River Country Estate
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/the-village-guest-house"
                  >
                    The Village Guest House
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/midvaal-gastehuis"
                  >
                    Midvaal Gastehuis
                  </a>
                </p>
              </li>
            </ul>
          </>
        );
      },
    },
    greeting: "We look forward to sharing our special day with you",
  },
  af: {
    title: "Josh & Mira",
    timeFancy: "Drie-uur",
    invite:
      "Andries en Adéle Vorster nooi u hartlik uit na die bruilof van hulle dogter",
    inviteJoiner: "met",
    inviteEnd: "Seun van Neil en Felicity Britz",
    venue: {
      title: "Waar op aarde?",
      description: () => {
        return (
          <React.Fragment>
            <a
              draggable={false}
              href="https://maps.app.goo.gl/1pRjnfgFuhyLP6fm9"
              target="_blank"
              rel="noreferrer noopener"
              className="venueBlock"
            >
              <h5>Die geleentheid vind plaas te</h5>
              <h1>Rivier Plaas, Meyerton</h1>
              <span>Langenhoven Rd, Sherman Park AH, Meyerton, 1961</span>
              <h5>
                Die huweliksbevestiging begin om <strong>15h00</strong>
              </h5>
            </a>
          </React.Fragment>
        );
      },
    },
    dressCode: {
      title: "Wat om aan te trek",
      description: () => {
        return (
          <p>
            Dit is 'n dag van vreugde en feesviering, ons vra dat u asseblief
            semi-formeel en so kleurvol as moontlik aantrek... moenie jou
            dansskoene vergeet nie.
          </p>
        );
      },
    },
    messageBoard: {
      title: "Ons trou amper",
      description: () => {
        return (
          <div className="messageBlock">
            <h4>
              Ons tel die sekondes af tot ons troudag <Countdown lang="af" />
            </h4>
          </div>
        );
      },
    },
    schedule: {
      title: "Bruilof Program",
      description: () => (
        <>
          <p style={{ paddingBottom: 24 }}>
            Ons is opgewonde om ons spesiale dag met u te deel. Vind asseblief
            die verloop van die dag hieronder. Die huweliksbevestiging begin om{" "}
            <strong>15h00</strong>.
          </p>

          <p>
            Ons vra dat gaste asseblief vanaf <strong>14h30</strong> opdaag
            sodat die huweliksbevestiging om <strong>15h00</strong> kan begin.
          </p>
        </>
      ),
      items: [
        {
          title: "Begin aankom",
          time: "14h30",
          tagline: "Rivier Plaas",
        },
        {
          title: "Huweliksbevestiging",
          time: "15h00",
          tagline: "Rivier Plaas kapel",
        },
        {
          title: "Groepsfoto",
          time: "16h20",
          tagline: "Buite die kapel",
        },
        {
          title: "Tee en koffie",
          time: "16h30",
          tagline: "Word bedien terwyl die bruidspaar fotos neem",
        },
        {
          title: "Onthaal",
          time: "18h00",
          tagline: "In die saal oorkant die kapel",
        },
      ],
      note: `Ons voorsien koffie, tee en drinkgoed saam met wyn by die tafels. Ander drinkgoed sal by die kontantkroeg beskikbaar wees. Ongelukkig word gaste nie toe gelaat om hul eie alkohol saam te bring nie.`,
    },
    rsvp: {
      title: "RSVP",
      description: () => {
        return (
          <div className="rsvpBlock">
            <p>
              Ons vra dat gaste asseblief teen <strong>31 Oktober 2024</strong>{" "}
              RSVP sodat ons die finale getalle kan bevestig.
            </p>

            <p>
              RSVP asseblief vir <strong>Josh</strong> of <strong>Mira</strong>.
              <br />
              <strong>Josh</strong>: 0662669858
              <br />
              <strong>Mira</strong>: 0725325279
            </p>
          </div>
        );
      },
    },
    lodging: {
      title: "Verblyf",
      description: () => {
        return (
          <>
            <p>
              Gaste wat wil oorslaap is welkom om die volgende gastehuise te
              kontak:
            </p>

            <ul className="lodgingList">
              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/plaaskombuis-country-estate"
                  >
                    Plaaskombuis
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/klip-river-country-estate"
                  >
                    Klip River Country Estate
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/the-village-guest-house"
                  >
                    The Village Guest House
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <a
                    className="clearLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.lekkeslaap.co.za/accommodation/midvaal-gastehuis"
                  >
                    Midvaal Gastehuis
                  </a>
                </p>
              </li>
            </ul>
          </>
        );
      },
    },
    greeting: "Ons verwelkom u graag daar",
  },
};

import "./App.css";
import { Banner } from "./components/banner/banner";
import { Constrainer } from "./components/constrainer/constrainer";
import { Container } from "./components/container/container";
import { PhotoPane } from "./components/photo-pane/photo-pane";
import { Schedule } from "./components/schedule/schedule";
import { Section } from "./components/section/section";
import { LanguageEngine } from "./language/language";

function App({ lang }: { lang: "en" | "af" }) {
  const copySource = LanguageEngine[lang] ?? LanguageEngine.af;

  return (
    <div className="App">
      <Container
        monogram
        topTitle={"13.12.2024"}
        bottomTitle={copySource.timeFancy}
      >
        <Constrainer>
          <img
            src="ornament1.svg"
            className="displayOrnament displayOrnamentTop"
            alt=""
          />

          <h4 className={"minifyH4"}>{copySource.invite}</h4>

          <h1 className="display2 displayHeading">Miranda Vorster</h1>

          <h4 className={"minifyH4 joiner"}>
            <img src="/endpoint1.svg" alt="" />
            {copySource.inviteJoiner}
            <img src="/endpoint1.svg" alt="" />
          </h4>

          <h1 className="display2 displayHeading">Joshua Britz</h1>

          <h4 className={"minifyH4"}>{copySource.inviteEnd}</h4>

          <img
            src="ornament1.svg"
            className="displayOrnament displayOrnamentBottom"
            alt=""
          />

          <Banner text="Rivier Plaas, Meyerton" />
        </Constrainer>
      </Container>

      <div>
        <PhotoPane
          images={[
            "/gallery/miraenjosh2.jpeg",
            "/gallery/ring.jpeg",
            "/gallery/hold.jpeg",
          ]}
        />
      </div>

      <Container topTitle={copySource.venue.title}>
        <Constrainer useOrnaments>
          <copySource.venue.description />
        </Constrainer>

        <div className="bgImage">
          <img src="/map.svg  " alt="" />
        </div>
      </Container>

      <Section title={copySource.schedule.title}>
        <copySource.schedule.description />

        <Schedule
          items={copySource.schedule.items}
          note={copySource.schedule.note}
        />
      </Section>

      <Container topTitle={copySource.messageBoard.title}>
        <Constrainer useOrnaments>
          <copySource.messageBoard.description />
        </Constrainer>
      </Container>

      <Section title={copySource.dressCode.title}>
        <copySource.dressCode.description />
      </Section>

      <div>
        <PhotoPane
          images={[
            "/gallery/bottom4.jpeg",
            "/gallery/bottom2.jpeg",
            "/gallery/bottom3.jpeg",
          ]}
        />
      </div>

      <Container topTitle={copySource.rsvp.title}>
        <Constrainer useOrnaments>
          <copySource.rsvp.description />
        </Constrainer>
      </Container>

      <Section title={copySource.lodging.title}>
        <copySource.lodging.description />
      </Section>

      <div className="greetingWrapper">
        <img src="/endcap.svg" alt="" />

        <h1 className="display2">{copySource.greeting}</h1>

        <img src="/endcap.svg" alt="" />
      </div>
    </div>
  );
}

export default App;

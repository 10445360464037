import React, { PropsWithChildren } from "react";

export function Constrainer(
  props: PropsWithChildren<{ useOrnaments?: boolean }>
): JSX.Element {
  const { useOrnaments = false } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        zIndex: 2,
      }}
    >
      <div className="comforter">
        {useOrnaments && (
          <img
            src="ornament1.svg"
            className="displayOrnament displayOrnamentTop"
            alt=""
          />
        )}

        <div style={{ textAlign: "center" }}>{props.children}</div>

        {useOrnaments && (
          <img
            src="ornament1.svg"
            className="displayOrnament displayOrnamentBottom"
            alt=""
          />
        )}
      </div>
    </div>
  );
}

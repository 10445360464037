import React from "react";
import styles from "./schedule.module.css";
import { ScheduleItem } from "../../language/language";

interface IScheduleProps {
  items: ScheduleItem[];
  note?: string;
}

export function Schedule(props: IScheduleProps): JSX.Element {
  return (
    <div className={styles.scheduleWrapper}>
      <ul>
        {props.items.map((item) => {
          return (
            <li key={item.title}>
              <div className={styles.time}>{item.time}</div>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.tagline}>
                {item.tagline}
                {item.hasNote ? <b>*</b> : <React.Fragment />}
              </div>
            </li>
          );
        })}
      </ul>

      {!!props.note && (
        <div className={styles.scheduleNote}>
          <p>
            <i>
              <small>{props.note}</small>
            </i>
          </p>
        </div>
      )}
    </div>
  );
}

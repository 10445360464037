import styles from "./photo-pane.module.css";

export function PhotoPane({
  images,
}: {
  images: [string, string, string];
}): JSX.Element {
  const [one, two, three] = images;

  return (
    <div className={styles.photoPaneWrapper}>
      <figure className={styles.photoPaneFigure}>
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />

        <img className={styles.photoPanePhoto} src={one} alt="" />
      </figure>

      <figure className={styles.photoPaneFigure}>
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />

        <img className={styles.photoPanePhoto} src={two} alt="" />
      </figure>

      <figure className={styles.photoPaneFigure}>
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />
        <img src="/corner.svg" alt="" />

        <img className={styles.photoPanePhoto} src={three} alt="" />
      </figure>
    </div>
  );
}

import { useState, useEffect } from 'react';

function useMediaQuery(query: MediaQueryList | string): boolean {
  let mediaQuery: MediaQueryList;
  if (typeof query === 'string') {
    mediaQuery = window.matchMedia(query);
  } else mediaQuery = query;

  const [matches, set] = useState(mediaQuery.matches);

  useEffect(() => {
    const handler = () => set(mediaQuery.matches);
    mediaQuery.addEventListener('change', handler);

    return () => {
      mediaQuery.removeEventListener('change', handler);
    };
  }, [mediaQuery]);

  return matches;
}

export { useMediaQuery };


import React from "react";
import ReactDOM from "react-dom/client";
import "./variables.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Container } from "./components/container/container";
import { LandingPage } from "./pages/landing/landing";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path={"/en"} element={<App lang="en" />} />
      <Route path={"/af"} element={<App lang="af" />} />

      <Route
        path={"/welcome"}
        element={
          <Container topTitle="13.12.2024" minHeight={"100vh"} centerContent>
            <LandingPage />
          </Container>
        }
      />
      <Route path={"/*"} element={<Navigate to={"/welcome"} />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { PropsWithChildren } from "react";
import styles from "./section.module.css";
import { Hr } from "../hr/hr";
import { useMediaQuery } from "../../library/useMediaQuery";

interface ISectionProps {
  title: string;
}

export function Section(props: PropsWithChildren<ISectionProps>): JSX.Element {
  let bottom = 97;
  let maxWidth = 350;

  const isTablet = useMediaQuery("screen and (max-width: 930px)");
  const isSmallTablet = useMediaQuery("screen and (max-width: 750px)");
  const isMobile = useMediaQuery("screen and (max-width: 580px)");

  if (isTablet) {
    bottom = 47;
    maxWidth = 250;
  } else if (isSmallTablet) {
    bottom = 47;
    maxWidth = 250;
  } else if (isMobile) {
    bottom = 47;
    maxWidth = 250;
  }

  return (
    <div className={styles.sectionWrapper}>
      <Hr paddingBottom={bottom} maxWidth={maxWidth} />

      <header className={styles.sectionHeader}>
        <h3>{props.title}</h3>
      </header>

      <div className={styles.sectionContent}>{props.children}</div>

      <Hr paddingBottom={bottom} maxWidth={maxWidth} />
    </div>
  );
}

import React from "react";
import styles from "./banner.module.css";

interface IBannerProps {
  text: string;
}

export function Banner(props: IBannerProps): JSX.Element {
  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerInner}>
        <img src="/bannerLeft.svg" alt="" />
        {props.text}
        <img src="/bannerRight.svg" alt="" />
      </div>
    </div>
  );
}
